import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const { REACT_APP_API_URL, REACT_APP_ENV } = process.env;

const host = window.location.host;

const getendPoints = (webHost, appEnv, appApiUrl) => {
    let dogbyte_env = undefined
    const isGreen = host.includes('green');
    const isBlue = host.includes('blue');
    let endpointLocal = appApiUrl;

    if (appEnv === 'local' || !appEnv){
        endpointLocal = appApiUrl;

    } else if (appEnv === 'prd'){
        dogbyte_env = webHost?.split('.')?.[0]?.split('checkin-')?.[1];
        if (dogbyte_env){
            endpointLocal = 'https://' + 'api-' + dogbyte_env + '.' + appApiUrl;
        }
        else if (isGreen) {
            endpointLocal = 'https://' + 'api-' + 'green' + '.' + appApiUrl;
        } else if (isBlue) {
            endpointLocal = 'https://' + 'api-' + 'blue' + '.' + appApiUrl;
        } else {
            endpointLocal = 'https://' + 'api' + '.' + appApiUrl;
        }

    } else {
        dogbyte_env = webHost?.split('.')?.[0]?.split('checkin-')?.[1];
        if (dogbyte_env){
            endpointLocal = 'https://' + 'api-' + dogbyte_env + '.' + appApiUrl;
        }
        else if (isGreen) {
            endpointLocal = 'https://' + 'api-' + 'green-' + dogbyte_env + '.' + appApiUrl;
        } else if (isBlue) {
            endpointLocal = 'https://' + 'api-' + 'blue-' + dogbyte_env + '.' + appApiUrl;
        } else {
            endpointLocal = 'https://' + 'api-' + dogbyte_env + '.' + appApiUrl;
        }

    }

    return {
        endpoint: endpointLocal,
    }

}

const { endpoint } = getendPoints(host, REACT_APP_ENV, REACT_APP_API_URL);


const emptySplitApi = createApi({
    reducerPath: "api",
    tagTypes: [
        'Checkin'
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${endpoint}`,
        credentials: "include",
        prepareHeaders: (headers, { getState }) => {
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    endpoints: () => ({}),
});

export default emptySplitApi;
